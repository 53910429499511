import { collection, getDocs } from "firebase/firestore";
import { db } from "../../infrastructure/firebase";

export const getHarmonizedSystems = async () => {
  const harmonizedSystems = [];
  const ref = collection(db, "harmonized_systems");
  const snapshot = await getDocs(ref);
  snapshot.forEach((doc) => {
    harmonizedSystems.push({
      ...doc.data(),
      id: doc.id,
    });
  });
  return harmonizedSystems;
};
