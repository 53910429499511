import "./App.css";
import { Layout, Menu } from "antd";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import HarmonizedSystemsPage from "./pages/harmonizedSystems";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Layout.Sider>
          <Menu theme="dark" mode="inline">
            <Menu.Item key="home">
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item key="hsCode">
              <Link to="/hsCode">HS Code</Link>
            </Menu.Item>
          </Menu>
        </Layout.Sider>
        <Layout.Content
          style={{ height: "100vh", padding: 32, overflow: "auto" }}
        >
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/hsCode" element={<HarmonizedSystemsPage />} />
          </Routes>
        </Layout.Content>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
