import "./home.css";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Progress,
  Row,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import instance from "../../axios";
import { Month } from "../../constant";
import moment from "moment";
import { analytic } from "../../infrastructure/firebase";
import { logEvent } from "firebase/analytics";

const HomePage = () => {
  const [form] = Form.useForm();
  const [sessionId, setSessionId] = useState();
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);

  const download =
    (filename) =>
    ({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); //or any other extension
      document.body.appendChild(link);
      link.click();
    };

  const onFinish = async ({ s, sessionId, totalRound, monthPicker: date, minQuantity }) => {
    try {
      setLoading(true);
      logEvent(analytic, 'submit');
      setCurrent(0);
      const month = date.get("month") + 1;
      const year = date.get("year");
      let tmp = {
        hsCodes: [],
        importData: [],
      };
      for (let i = 0, lastCode; i < totalRound; ) {
        try {
          setCurrent(i);
          const { data } = await instance.get("/getInfo", {
            params: {
              s,
              session_id: sessionId,
              r: i,
              month,
              year,
              lastCode,
              minQuantity,
            },
          });
          tmp.hsCodes = tmp.hsCodes.concat(data.hs_code);
          tmp.importData = tmp.importData.concat(data.csv);
          lastCode = data.hsCode;
          i += 1;
        } catch (error) {
          console.log("error attempt fetch again", error);
          throw new Error(`Fetch round ${i} failed, try again`);
        }
      }
      setCurrent(totalRound);
      await instance
        .post(
          "/createHsCodeCsv",
          {
            csv: tmp.hsCodes,
            month,
            year,
          },
          { responseType: "blob" }
        )
        .then(download(`hs_code_${Month[month]}_${year}.csv`));
      await instance
        .post(
          "/createImportDataCsv",
          {
            csv: tmp.importData,
            month,
            year,
          },
          { responseType: "blob" }
        )
        .then(download(`import_data_${Month[month]}_${year}.csv`));
    } catch (error) {
      Modal.error({
        title: "Error",
        content: error.message,
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!sessionId) {
      instance.post("/getThaiCustoms").then(({ data }) => {
        const { totalRound, sessionId, s } = data;
        setSessionId(sessionId);
        setTotal(totalRound);
        form.setFieldsValue({
          s,
          sessionId: sessionId,
          totalRound: totalRound,
        });
      });
    }
  });

  if (!sessionId) {
    return (
      <Row>
        <Col>
          <Spin size="large" />
          Initializing
        </Col>
      </Row>
    );
  }

  return (
    <div className="App">
      <main>
        <Row justify="center">
          <Col span={6}>
            <Card>
              <Form
                name="test"
                initialValues={{ monthPicker: moment() }}
                form={form}
                layout="vertical"
                onFinish={onFinish}
              >
                <Form.Item required name="s" label="s">
                  <Input disabled />
                </Form.Item>
                <Form.Item required name="sessionId" label="Session id">
                  <Input disabled />
                </Form.Item>
                <Form.Item required name="totalRound" label="Total round">
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="monthPicker"
                  label="Select date"
                  rules={[
                    {
                      type: "object",
                      required: true,
                      message: "Please select time!",
                    },
                  ]}
                >
                  <DatePicker disabled={loading} picker="month" />
                </Form.Item>
                <Form.Item
                  name="minQuantity"
                  label="Minimum quantity"
                  rules={[
                    {
                      type: "integer",
                      required: true,
                      message: "Please fill min monthly quantity",
                    },
                  ]}
                  initialValue={100000}
                > 
                  <InputNumber />
                </Form.Item>
                <Button
                  id="submit-button"
                  disabled={loading || !sessionId}
                  type="primary"
                  htmlType="submit"
                >
                  Generate
                </Button>
              </Form>
              <Progress
                percent={((current * 100) / total).toFixed(2)}
                status={current === total ? "success" : "active"}
              />
            </Card>
          </Col>
        </Row>
      </main>
    </div>
  );
};

export default HomePage;
