import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Space, Table } from "antd";
import { useEffect, useState, useRef } from "react";
import Highlighter from "react-highlight-words";
import { createHarmonizedSystem } from "../../repositories/harmonizedSystems/createHarmonizedSystem";
import { deleteHarmonizedSystem } from "../../repositories/harmonizedSystems/deleteHarmonizedSystem";
import { getHarmonizedSystems } from "../../repositories/harmonizedSystems/getHarmonizedSystems";

const HarmonizedSystemsPage = () => {
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      setData(await getHarmonizedSystems());
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteClicked = (id) => async () => {
    Modal.confirm({
      title: "Are you sure ?",
      onOk: async () => {
        try {
          await deleteHarmonizedSystem(id);
        } catch (error) {
          console.error(error);
        } finally {
          fetchAll();
        }
      },
    });
  };
  useEffect(() => {
    fetchAll();
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const onSubmit = async (values) => {
    try {
      setCreating(true);
      await createHarmonizedSystem(values);
      Modal.info({
        title: "Create successfully",
        content: "Create HS Code succesfully",
        onOk: async () => {
          fetchAll();
        },
      });
    } catch (error) {
      console.error(error);
      Modal.error({
        title: "Create failed",
        content:
          "Create HS Code failed, please try again. (This code may be existed)",
        onOk: async () => {
          fetchAll();
        },
      });
    } finally {
      setCreating(false);
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Form onFinish={onSubmit} layout="inline">
            <Form.Item name="code" fieldKey="code" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" loading={creating} htmlType="submit">
                Create HS Code
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          loading={loading}
          dataSource={data}
          columns={[
            {
              dataIndex: "code",
              title: "Code",
              key: "code",
              ...getColumnSearchProps("code"),
            },
            {
              dataIndex: "id",
              key: "id",
              title: "",
              render: (id) => (
                <Button type="primary" danger onClick={onDeleteClicked(id)}>
                  <DeleteOutlined />
                </Button>
              ),
              width: 20,
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default HarmonizedSystemsPage;
