import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const config = {
  apiKey: "AIzaSyCj6hm0wnQF1KtjOM5FTheZWUut4eys42g",
  authDomain: "thai-customs.firebaseapp.com",
  projectId: "thai-customs",
  storageBucket: "thai-customs.appspot.com",
  messagingSenderId: "92555168151",
  appId: "1:92555168151:web:a0a074fafdcd379a4b1cd2",
};

export const app = initializeApp(config);
export const db = getFirestore();
export const analytic = getAnalytics();
